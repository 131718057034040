window.$ = window.jQuery = require('jquery');
require('popper.js');
require('bootstrap');

require('../../../../modules/system/assets/js/framework.js');
require('../../../../modules/system/assets/js/framework.extras.js');

require('../template/js/common_scripts.js');
require('../template/js/jquery.sliderPro.min.js');
require('../template/js/map.js');
require('../template/js/functions.js');

$('#Img_carousel').sliderPro({
    width: 960,
    height: 500,
    fade: true,
    arrows: true,
    buttons: false,
    fullScreen: false,
    smallSize: 500,
    startSlide: 0,
    mediumSize: 1000,
    largeSize: 3000,
    thumbnailArrows: true,
    autoplay: false
});
$('.carousel-thumbs-2').owlCarousel({
    loop:false,
    margin:5,
    responsiveClass:true,
    nav:false,
    responsive:{
        0:{
            items:1
        },
        600:{
            items:3
        },
        1000:{
            items:4,
            nav:false
        }
    }
});
